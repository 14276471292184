export const dispatchEvent = ({ eventName, args, element }) => {
    // Use the provided element or fallback to window if available
    if (!element) {
        if (typeof window !== 'undefined') {
            element = window
        } else {
            throw new Error(
                '`element` is not provided and `window` is unavailable. Provide a valid element to dispatch the event.'
            )
        }
    }

    let event
    if (args) {
        event = new CustomEvent(eventName, { detail: args, bubbles: true })
    } else {
        if (typeof Event === 'function') {
            event = new Event(eventName)
        } else {
            event = document.createEvent('Event')
            event.initEvent(eventName, true, true)
        }
    }
    element.dispatchEvent(event)
}

export const readEvent = e => {
    if (!e.detail) {
        return
    }
    return e.detail
}
