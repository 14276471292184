import createProps from '@kissui/helpers/src/props.helpers'
import EventBus from '@kissui/helpers/src/assets/js/eventBus'
import { dispatchEvent } from '@kissui/helpers/src/assets/js/eventDispatch'
import {
    handlePromoClick,
    productImpression,
    trackComponentInteraction
} from '@kissui/helpers/src/gtmEvents'
import { EVENT_POPIN_OPEN, EVENT_QS_OPEN, TIME_SLOW } from '@kissui/components'
import { getLangCode, getMarketCode } from '@kissui/helpers/src/dataLayer'

class Cta extends HTMLElement {
    constructor() {
        super()
        this.props = {}
        this.market = getMarketCode()
        this.language = getLangCode()
    }

    static get observedAttributes() {
        return ['slot', 'quantity']
    }

    connectedCallback() {
        this.props = {
            ...createProps(this.attributes),
            disableTracking: this.hasAttribute('data-disabletracking')
        }

        this.boundHandleClick = this.handleClick.bind(this)
        this.slot = this.innerHTML.replace(/\n*$/, '').trim()
        this.render()
    }

    render() {
        const {
            add_to_cart = '',
            aria_label,
            aria_label_after,
            aria_label_before,
            contrast,
            icon_left = '',
            icon_right = '',
            label_update,
            label,
            link_new_tab = false,
            long_sku = '',
            seo_label = '',
            size = '48',
            tracking_list = '',
            tracking_position = '',
            transparent = 'false',
            variation
        } = this.props
        let { link } = this.props

        const slot = this.slot ? this.slot : label

        const element = document.createElement(link ? 'a' : 'button')

        if (tracking_position && tracking_list && long_sku) {
            productImpression(tracking_position, tracking_list, long_sku)
        }

        if (link) {
            link = link.replace('{market}', this.market)
            link = link.replace('{lang}', this.language)

            element.setAttribute('href', link)
        }

        if (link && link_new_tab) {
            element.setAttribute('target', '_blank')
        }
        // Todo : can we get rid of this title attr ? at least when it's a button, not a link ?
        if (seo_label && link) {
            element.setAttribute('title', seo_label)
        }

        if (aria_label) {
            element.setAttribute('aria-label', aria_label)
        } else if (aria_label_before || aria_label_after) {
            const ariaLabel = [aria_label_before, slot, aria_label_after]
                .filter(value => !!value)
                .join(' ')
            ariaLabel && element.setAttribute('aria-label', ariaLabel)
        }

        if (variation !== 'navigation') {
            element.classList.add(`button--cta`)
        }
        element.classList.add(`button--${variation}`)
        if (contrast) {
            if (transparent === 'true') {
                element.classList.add(`button--${contrast}--transparent`)
            } else {
                element.classList.add(`button--${contrast}`)
            }
        }
        if (size) {
            let textClass = 't-md-400-sl'
            switch (size) {
                case '32':
                    textClass = 't-xs-500-sl'
                    break
                case '40':
                    textClass = 't-sm-400-sl'
                    break
            }
            element.classList.add(`size--${size}`, textClass)
        }

        if ((variation === 'primary' || variation === 'tertiary') && add_to_cart !== '') {
            this.setAttribute('add_to_cart', add_to_cart)
            this.setAttribute('quantity', '0')
            add_to_cart === 'small'
                ? element.classList.add('addToCart--small')
                : element.classList.add('addToCart--large')
        }

        icon_left && this.setAttribute('icon_left', icon_left)
        icon_right && this.setAttribute('icon_right', icon_right)

        element.innerHTML = `
                ${icon_left && `<nb-icon icon='${icon_left}'></nb-icon>`}
                <span>${slot}</span>
                ${
                    variation === 'primary' && add_to_cart !== '' && label_update !== ''
                        ? `<span class="update">${label_update}</span>`
                        : ''
                }
                ${icon_right && `<nb-icon icon='${icon_right}'></nb-icon>`}
        `
        this.innerHTML = element.outerHTML

        this.removeListeners()
        this.addListeners()
    }

    handleClick() {
        if (this.props.disableTracking === false) {
            this.track()
        }

        const { event_id = '', popin_id = '', qs_id = '' } = this.props

        if (event_id) {
            EventBus.fire(event_id, {})
        }
        if (popin_id) {
            this.popin(popin_id)
        }
        if (qs_id) {
            this.openQuantitySelector(qs_id)
        }
    }

    popin(popin_id) {
        dispatchEvent({ eventName: EVENT_POPIN_OPEN, args: { id: popin_id } })
    }

    openQuantitySelector(qs_id) {
        dispatchEvent({ eventName: EVENT_QS_OPEN, args: { id: qs_id } })
    }

    track() {
        const {
            campaign_id = '',
            campaign_name = '',
            campaign_position = '',
            campaign_creative = '',
            campaign_instance_index = '',
            tracking_list,
            label,
            popin_id = '',
            tracking_slot_prefix = '',
            sku_data = ''
        } = this.props

        const campaign = {
            id: campaign_id,
            name: campaign_name,
            position: campaign_position,
            creative: campaign_creative
        }

        const ctaOrPopin = popin_id ? 'POPIN' : 'CTA'
        const slot = this.slot ? this.slot : label
        const slotContent = `${tracking_slot_prefix}${slot}`
        const instanceIndex = campaign_instance_index ? ` - ${campaign_instance_index}` : ''
        const tabbedComponentStatus = JSON.parse(this.props?.tabbed_component ?? 'false')

        let cta_name_value = tabbedComponentStatus
            ? `${ctaOrPopin + instanceIndex} - ${
                  this.props.long_sku.split('/')[2]
              } - ${slotContent}`
            : `${ctaOrPopin + instanceIndex} - ${slotContent}`

        handlePromoClick({
            campaign,
            cta_name: cta_name_value
        })

        if (popin_id && sku_data) {
            const product = JSON.parse(sku_data)
            const componentName =
                tracking_list.indexOf(' - ') > -1 ? tracking_list.split(' - ')[0] : tracking_list
            trackComponentInteraction({
                creative: this.props.campaign_creative ?? '',
                actionType: componentName.toLowerCase() + ' quick view',
                internationalId: product.internationalId,
                internationalName: product.internationalName,
                productType: product.type,
                technology: product.technology,
                category: product.category,
                rawPrice: product.rawPrice,
                eventAction: componentName + ' Quick View'
            })
        }
    }

    attributeChangedCallback(name) {
        if (name === 'slot') {
            this.render()
        }
        if (name === 'quantity') {
            this.classList.add('updated')
            setTimeout(() => {
                this.classList.remove('updated')
            }, TIME_SLOW)
        }
    }

    addListeners() {
        this.addEventListener('click', this.boundHandleClick)
    }

    removeListeners() {
        this.removeEventListener('click', this.boundHandleClick)
    }

    disconnectedCallback() {
        this.removeListeners()
    }
}

customElements.get('nb-cta') || customElements.define('nb-cta', Cta)

export default Cta
